import { DialogContent, DialogOverlay } from "@reach/dialog"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import styled from "styled-components"
import { BREAKPOINTS } from "../../constants"
import "@reach/dialog/styles.css"

const ImageModalOverlay = styled(DialogOverlay)`
  display: grid;
  place-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--color-background-base);
  background-image: radial-gradient(
    var(--color-theme-accent) 0.16px,
    var(--color-background-base) 1px
  );
  background-size: 20px 20px;
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  animation: fade-in 640ms ease;
  @keyframes scale-up {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  > [data-reach-dialog-content] {
    animation: scale-up 240ms ease;
  }
`
const ImageModalContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36vw;
  max-width: 800px;
  cursor: default;
  @media (max-width: ${BREAKPOINTS["medium-viewport"]}) {
    width: 68vw;
  }
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    width: 90vw;
  }
  padding: 0;
  border-radius: 4px;
  background: none;
  > div {
    box-shadow: 4px 4px 4.6169px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(8px 8px 8px rgb(0 0 0 / 12%));
    border-radius: 4px;
  }
  > p {
    text-align: center;
    margin-top: calc(var(--safety-padding) / 2);
    color: var(--color-theme-text);
  }
`

const GalleryWrapper = styled.div`
  padding: var(--safety-padding);
  width: calc(100% - var(--safety-padding) * 2) !important;
  margin: 0 !important;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(
    ${props => Math.floor(Math.sqrt(props.count))},
    1fr
  );
  @media (max-width: ${BREAKPOINTS["medium-viewport"]}) {
    grid-template-columns: repeat(${props => (props.count > 1 ? 2 : 1)}, 1fr);
    gap: 0.5rem;
  }
`
const GalleryImage = styled(GatsbyImage)`
  box-shadow: 4px 4px 4.6169px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  cursor: pointer;
  will-change: transform;
  > picture > img {
    border-radius: 4px;
  }
  :hover {
    filter: drop-shadow(8px 8px 8px rgb(0 0 0 / 12%));
    transform: rotate(1deg);
  }
`

const GalleryCloseButton = styled.button`
  border: 0;
  padding: 0;
  background: var(--color-background-24-dp-elevation);
  color: var(--color-theme-accent);
  font-size: 2.5rem;
  cursor: pointer;
  border-radius: 100%;
  width: 4rem;
  height: 4rem;
  margin-top: calc(var(--safety-padding) / 2);
  box-shadow: 4px 4px 4.6169px rgba(0, 0, 0, 0.25);
`

function Gallery(props) {
  const [showLightBox, setShowLightBox] = useState({
    flag: false,
    image: undefined,
    alt: undefined,
  })

  const close = e => {
    const animationTime = 640
    document.getElementById(
      "ImageModalOverlay"
    ).style.animation = `fade-out ${animationTime}ms ease`
    setTimeout(setShowLightBox, animationTime, {
      flag: false,
      image: undefined,
      alt: undefined,
    })
  }

  const open = (image, alt) =>
    setShowLightBox({
      flag: true,
      image,
      alt,
    })

  return props.images ? (
    <>
      <GalleryWrapper className={props.className} count={props.images.length}>
        {props.images.map(node => {
          return (
            <div
              key={node.id}
              onClick={() =>
                open(node.childImageSharp.gatsbyImageData, node.name)
              }
              tabIndex="0"
              role="button"
              onKeyDown={e => {
                if (e.key === " ") {
                  open(node.childImageSharp.gatsbyImageData, node.name)
                }
              }}
            >
              <GalleryImage
                image={node.childImageSharp.gatsbyImageData}
                alt={`Gallery Image titled ${node.name}`}
              />
            </div>
          )
        })}
      </GalleryWrapper>
      {showLightBox.flag && (
        <ImageModalOverlay
          onDismiss={close}
          aria-label="Close Image"
          allowPinchZoom={true}
          id="ImageModalOverlay"
        >
          <ImageModalContent aria-label="Fullscreen Image preview">
            <GatsbyImage
              image={showLightBox.image}
              aria-label="Close Image"
              alt={`Gallery Image titled ${showLightBox.alt}`}
            />
            <p>{showLightBox.alt}.png</p>
            <GalleryCloseButton onClick={close}>&times;</GalleryCloseButton>
          </ImageModalContent>
        </ImageModalOverlay>
      )}
    </>
  ) : null
}

export default Gallery
