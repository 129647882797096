import React from "react"
import Head from "../components/head"
import Layout from "../layouts/layout"
import { SectionHeader } from "../components/Common"
import styled from "styled-components"
import { BREAKPOINTS } from "../constants"
import Gallery from "../components/common/gallery"
import { graphql, useStaticQuery } from "gatsby"

import SparkleIcon from "../../static/website_assets/Sparkle.svg"
import { StaticImage } from "gatsby-plugin-image"

const AboutImageComponent = styled.div`
  height: auto;
  /* width: 32rem; */
  box-shadow: 4px 4px 4.6169px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  /* background-blend-mode: multiply, normal; */
  filter: grayscale(0%);
  background-size: cover;
  background-repeat: no-repeat;
  grid-column: 1;
  grid-row: 1;
  > div {
    height: 100%;
    @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
      height: 60vh;
    }
  }
  img {
    border-radius: 4px;
    @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
      object-position: center top;
    }
  }
  ::before,
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: var(--color-theme-gradient-2);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    mix-blend-mode: hue;
    opacity: 0.2;
    transition: all 120ms ease-in-out;
  }
  ::before {
    mix-blend-mode: color;
    opacity: 0.72;
    z-index: 2;
  }
  :hover,
  :active {
    filter: drop-shadow(8px 8px 8px rgb(0 0 0 / 12%));
    ::after,
    ::before {
      opacity: 0;
      z-index: -1;
      transition: all 120ms ease-in-out;
    }
  }
  @supports (-webkit-hyphens: none) {
    ::before,
    ::after {
      display: none;
    }
  }
`

const AboutContent = styled.div`
  width: fit-content;
  grid-column: 2;
  grid-row: 1;
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    grid-column: 1;
    grid-row: 2;
  }
  > * + * {
    margin-top: 1rem;
  }
`
const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  > * + * {
    margin-left: 1rem;
  }
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    grid-template-columns: 1fr;
    > * + * {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
`
const AboutDescription = styled.p`
  background: var(--color-background-48-dp-elevation);
  padding: 1rem;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`

const LifeUpdate = styled.div`
  .header {
    content: "Life Update";
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    ::before {
      opacity: 0.72;
      content: ".";
      display: inline-block;
      height: calc(var(--tags)+"4px");
      width: 28px;
      background-color: var(--color-theme-accent);
      mask: url(${SparkleIcon}) no-repeat left;
    }
  }
  background: var(--color-background-48-dp-elevation);
  padding: 1rem;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`

function AboutPage() {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          dir: { regex: "/about_gallery/" }
        }
      ) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(
              width: 1080
              placeholder: BLURRED
              formats: [PNG, WEBP]
            )
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Head title="About" />
      <SectionHeader>About Me</SectionHeader>
      <AboutContainer>
        <AboutImageComponent>
          <StaticImage
            src="../../static/website_assets/about.jpg"
            alt="Portrait image of Sourabh"
            placeholder="blurred"
          />
        </AboutImageComponent>
        <AboutContent>
          <h2>Hi, I'm Sourabh!</h2>
          {/* <LifeUpdate>
            <p className="header">Life Update</p>
            <p>
              I'm joining Nutanix as a UX Design Intern for Summer 2022.
            </p>
          </LifeUpdate> */}
          <AboutDescription>
I am a UI Engineer turned product designer. My expertise in tech lets me design solutions that are not only loved by users but also by the developers. Along with user data, technical feasibility is something that I prioritize when I design solutions.
          </AboutDescription>
          <AboutDescription>
           I believe that people, technology and design are the ingredients of building phenomenal products. After conquering the hill of technology, I am currently on to my next quest: I am studying Human-Computer Interaction at University of Maryland to conquer the hills of design and people. Here at UMD, I am learning to build accessible, inclusive and equitable products.
          </AboutDescription>
          <AboutDescription>
          My new found interest is in building technological solutions to enhance productivity and creativity. Feel free to reach out for a random chat or to work on something exciting!
          </AboutDescription>
        </AboutContent>
      </AboutContainer>
      <SectionHeader>Wall of Random Creations</SectionHeader>
      <Gallery className="full-bleed" images={data.allFile.nodes} />
    </Layout>
  )
}

export default AboutPage
